<template>
    <div style="display: flex; flex-direction: column; gap: 1rem; margin-top: 5rem;">
    <h1>Links</h1>

    <router-link to="/kpi">> KPI - website I created with my classmates for our school</router-link>
    <a href="rst.sk"> > RST - website I created for a client</a>
    <a href="gamedays.sk"> > GameDays - gaming conference for which I manage website in WordPress</a>
    <a href="https://www.tymobeauty.com/DIANA44602"> > TYMO 20% discount ( code: DIANA44602 )</a>
    </div>
</template>

<script>
</script>

<style>

</style>